<template>
	<div class="wrapper" id="nopms">
		<div class="head"></div>
		<div class="content">
			<div class="f-content">
				<div class="fail"></div>
				<div class="fail-msg">对不起！您当前无任何功能权限</div>
				<div class="fail-text">您可以联系系统管理员为您分配权限或者返回登录页用</br>其他账号登录</div>
			</div>
		</div>
	</div>
</template>

<script>
/**
 * @module app/load/nopms
 * @desc 传蔬农贸版后台 无权限页面
 * @version 0.0.1
 * @author songkexin <songkexin@rongyi.com>
 * @date   2021-01-10
 * @copyright 2021
 */
export default {
	name: 'nopms',
};
</script>

<style lang="less">
// @import url("../../less/common.less");
@import url("../../less/common.less");
#nopms{
	.pos-r();
	.f-content{
		position: absolute;
		.wh(300px,220px);
		left: calc(~'50% - 150px');
		top: calc(~'50% - 110px');
		.ta-c();
	}
	.fail{
		margin-left: auto;
		margin-right: auto;
		.wh(140px, 104px);
		background-image: url('/images/load/nopms.png');
	}
	.fail-msg{
		.fc(14px, #525E6E);
		.mgt(10px);
		font-weight: bold;
	}
	.fail-text{
		.fc(12px, #525E6E);
		.mgt(12px);
		line-height: 15px;
	}
}
</style>